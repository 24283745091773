<footer class="footer-fix-container" role="contentinfo">
  <div class="footer-fix">
    <a
      class="footer-fix-content"
      translate
      routerLink="{{ 'ROUTES.privacy' | translate }}"
    >
      footer.confidentialite</a
    >
    <a
      class="footer-fix-content"
      routerLink="{{ 'ROUTES.site-map' | translate }}"
      translate
    >
      footer.plan</a
    >
    <a
      class="footer-fix-content"
      translate
      routerLink="{{ 'ROUTES.legal-information' | translate }}"
      >footer.legale</a
    >
    <a
      class="footer-fix-content"
      translate
      routerLink="{{ 'ROUTES.cookies' | translate }}"
      >footer.cookies</a
    >
    <a
      class="footer-fix-content"
      translate
      routerLink="{{ 'ROUTES.cgu' | translate }}"
      >footer.cgu</a
    >
    <div
      (click)="scrollToTop()"
      (keyup.enter)="scrollToTop()"
      (keyup.space)="scrollToTop()"
      class="footer-fix-scroll"
      alt="button-scroll-to-top"
      aria-label="Revenir en haut de la page"
      tabindex="0"
    >
      <img
        src="../../../assets/site/white-arrow.svg"
        alt="anchor-top"
        role="img"
      />
    </div>
    <div class="footer-build-number" fxHide.sm fxHide.xs>
      Version : {{ buildNumber }}
    </div>
  </div>
</footer>
