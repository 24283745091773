import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from '../services/storage.service';
@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private layoutMap: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private modalSearchOppened: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private selectedMenu: BehaviorSubject<string> = new BehaviorSubject<string>(
    'home'
  );

  private language: BehaviorSubject<string> = new BehaviorSubject<string>(
    environment.language.default
  );

  constructor(
    private storage: StorageService,
    private translate: TranslateService
  ) {}

  public Language(): Observable<string> {
    return this.language.asObservable();
  }

  public setLanguage(value: string): void {
    this.language.next(value);
  }

  public LayoutMap(): Observable<boolean> {
    return this.layoutMap.asObservable();
  }
  /**
   * Permet le passage du layout du site en mode Carto ou Classique
   * En mode Carto, suppression du breadcrumb, et footer specifique
   */
  public setMapLayout(value: boolean): void {
    if (value) {
      const html = document.getElementsByTagName(
        'html'
      ) as HTMLCollectionOf<HTMLElement>;
      const body = document.getElementsByTagName('body');

      html[0].style.height = '100%';
      body[0].style.height = '100%';
    } else {
      const html = document.getElementsByTagName(
        'html'
      ) as HTMLCollectionOf<HTMLElement>;
      const body = document.getElementsByTagName('body');

      html[0].style.height = '';
      body[0].style.height = '';
    }
    this.layoutMap.next(value);
  }

  /**
   * Indique le menu en cours
   * @returns
   */
  public SelectedMenu(): Observable<string> {
    return this.selectedMenu.asObservable();
  }
  /**
   * Permet de definir le menu en cours
   * @param value
   */

  public setSelectedMenu(value: string): void {
    this.selectedMenu.next(value);
  }

  public switchLocale(language: string): void {
    this.storage.setDataString('locale', language);
    this.translate.use(language);
  }

  public setSearchModalDisplayed(value: boolean): void {
    this.modalSearchOppened.next(value);
  }

  public showSearchModal(): any {
    return this.modalSearchOppened.asObservable();
  }
}
