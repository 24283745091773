import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material.module';

// pipe
import { ArticleDate } from '../pipes/articleDate.pipe';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';

import { ImgDirective } from './directives/img.directive';
// composant
import { PopupInformationDialogComponent } from './popup-information/popup-information-dialog/popup-information-dialog.component';
import { PopupInformationComponent } from './popup-information/popup-information.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SearchModalComponent } from './search-modal/search-modal.component';
import { TuileAccueilComponent } from './tuile-accueil/tuile-accueil.component';
import { AgenciesListComponent } from './agencies-list/agencies-list.component';
import { NewsComponent } from './news/news.component';
import { EditorialPageComponent } from './editorial-page/editorial-page.component';
import { DynamicTextComponent } from './dynamic/dynamic-text/dynamic-text.component';
import { DynamicVideoComponent } from './dynamic/dynamic-video/dynamic-video.component';
import { DynamicImageComponent } from './dynamic/dynamic-image/dynamic-image.component';
import { DynamicVideoIframeComponent } from './dynamic/dynamic-video-iframe/dynamic-video-iframe.component';
import { DynamicFileComponent } from './dynamic/dynamic-file/dynamic-file.component';
import { DynamicAccordionComponent } from './dynamic/dynamic-accordion/dynamic-accordion.component';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    ArticleDate,
    TuileAccueilComponent,
    ImgDirective,
    SearchBarComponent,
    SearchModalComponent,
    PopupInformationComponent,
    PopupInformationDialogComponent,
    NewsComponent,
    AgenciesListComponent,
    EditorialPageComponent,
    DynamicTextComponent,
    DynamicVideoComponent,
    DynamicImageComponent,
    DynamicVideoIframeComponent,
    DynamicFileComponent,
    DynamicAccordionComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    TranslateModule,
    RouterModule,
    FormsModule,
  ],
  exports: [
    RouterModule,
    TranslateModule,
    MaterialModule,
    FlexLayoutModule,
    TuileAccueilComponent,
    SafeHtmlPipe,
    ArticleDate,
    ImgDirective,
    SearchBarComponent,
    PopupInformationComponent,
    NewsComponent,
    AgenciesListComponent,
    EditorialPageComponent,
  ],
})
export class SharedModule {}
