import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { contextAccount } from '../pages/connexion/interfaces/account';
import { IJourneyPlannerParams } from '../pages/itineraires/interfaces/JourneyPlannerEvent';
import { eventCtw, eventCtwJourneyPlanner, eventCtwMember, eventCtwSchedule } from '../shared/interface/ctwEnum';

@Injectable({
  providedIn: 'root',
})
/**
 * Service permettant de traiter les actions demandées par
 * les composants de CityWay
 * Pour l'action 'SetQueryParameters'
 * - redirection vers une route avec les parametres en query params
 * Pour l'action 'FullScreenAutocomp'
 * - comportement à determiner
 *
 *
 */
export class EventsCtwService {
  constructor(private router: Router, private translate: TranslateService) {}

  processEventDisrupt(event: any, locale: string) {
    if (event.detail.action === eventCtw.SetQueryParameters) {
      this.navigateWithSetQueryParams(
        event.detail.parameters,
        'ROUTES.public-transport-info',
        locale
      );
    }
    if (event.detail.action === eventCtwMember.LoginSigninForm) {
      this.navigateWithSetQueryParams(
        event.detail.parameters,
        'ROUTES.mon-compte',
        locale
      );
    }
  }

  /**
   * Redirection avec les parametres de la derniere recherche
   * @param searchParams
   * @param locale
   */
  processTripPlannerSearch(
    searchParams: IJourneyPlannerParams,
    locale: string
  ) {
    const parameters = new Map(Object.entries(searchParams));
    this.navigateWithSetQueryParams(parameters, 'ROUTES.itineraires', locale);
  }

  /**
   * Gestion de l'evenement SchedulePhysicalStop
   * Definir le comportement sur cet evenement
   *
   * @param parameters LineID\hrlibrary
   * @param locale
   */
  processScheduleLine(event: any, locale: string) {
    if (event.detail.action === eventCtwMember.LoginSigninForm) {
      this.navigateWithSetQueryParams(
        event.detail.parameters,
        'ROUTES.mon-compte',
        locale
      );
    }
    if (event.detail.action === eventCtwJourneyPlanner.ScheduleLine) {
      event.detail.parameters.set('context', eventCtwJourneyPlanner.ScheduleLine);
      this.navigateWithSetQueryParams(
        event.detail.parameters,
        'ROUTES.public-transport-hourly',
        locale
      );
    }

    if (event.detail.action === eventCtwSchedule.SchedulePhysicalStop) {
      event.detail.parameters.set('context', eventCtwSchedule.SchedulePhysicalStop);
      this.navigateWithSetQueryParams(
        event.detail.parameters,
        'ROUTES.public-transport-hourly',
        locale
      );
    }
  }

  /**
   * Pour l'action SetQueryParameters
   * transforme les parametres en queryParams pour la requete
   * et navigue vers la route en tenant compte de la locale
   *
   * Todo : queryParamsHandling : merge or preserve ?
   * @param parameters
   * @param route
   * @param locale
   */
  public navigateWithSetQueryParams(
    parameters: any,
    route: string,
    locale: string
  ) {
    const queryParamsObj: any = {};

    parameters.forEach((value: boolean, key: string) => {
      queryParamsObj[key] = value;
    });
    this.router.navigate(['/', locale, this.translate.instant(route)], {
      queryParams: queryParamsObj,
    });
  }

  public navigateWithSetQueryParamsMerged(
    parameters: any,
    route: string,
    locale: string
  ) {
    const queryParamsObj: any = {};

    parameters.forEach((value: boolean, key: string) => {
      queryParamsObj[key] = value;
    });
    this.router.navigate(['/', locale, this.translate.instant(route)], {
      queryParams: queryParamsObj,
      queryParamsHandling: 'merge',
    });
  }

  public processEventMember(event: any, locale: string) {
    if (event.detail.action === eventCtw.SetQueryParameters) {
      const context = event.detail.parameters.get('context');
      const path = [locale, this.translate.instant('ROUTES.mon-compte')];
      if (context !== 'MemberHome') {
        path.push(this.getObjKey(contextAccount, context));
        this.router.navigate(path);
      } else {
        this.navigateWithSetQueryParamsMerged(
          event.detail.parameters,
          'ROUTES.mon-compte',
          locale
        );
      }
    }

    if (event.detail.action === eventCtwSchedule.ScheduleForm) {
      this.router.navigate([
        locale,
        this.translate.instant('ROUTES.public-transport-hourly'),
      ]);
    }
    if (event.detail.action === eventCtwMember.LoggedOut) {
      this.router.navigate([locale]);
    }
    if (event.detail.action === eventCtw.ContactForm) {
      this.router.navigate([locale, this.translate.instant('ROUTES.contact')]);
    }
    if (event.detail.action === eventCtw.LegalNotices) {
      this.router.navigate([
        locale,
        this.translate.instant('ROUTES.legal-information'),
      ]);
    }
    if (event.detail.action === eventCtw.Gdpr) {
      this.router.navigate([
        locale,
        this.translate.instant('ROUTES.legal-information'),
      ]);
    }
    if (event.detail.action === eventCtwSchedule.SchedulePhysicalStop) {
      event.detail.parameters.set('context', eventCtwSchedule.SchedulePhysicalStop);
      this.navigateWithSetQueryParams(
        event.detail.parameters,
        'ROUTES.public-transport-hourly',
        locale
      );
    }
    if (event.detail.action === eventCtwJourneyPlanner.ScheduleLine) {
      event.detail.parameters.set('context', eventCtwJourneyPlanner.ScheduleLine);
      this.navigateWithSetQueryParams(
        event.detail.parameters,
        'ROUTES.public-transport-hourly',
        locale
      );
    }
    if (event.detail.action === eventCtw.NearbyHome) {
      this.router.navigate([
        locale,
        this.translate.instant('ROUTES.carte-interactive'),
      ]);
    }
  }

  private getObjKey(obj: any, value: string) {
    return Object.keys(obj).find((key) => obj[key] === value);
  }
}
