import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'breadcrumbMobile',
})
export class BreadcrumbMobilePipe implements PipeTransform {
  constructor() {}

  transform(value: any): any {
    return value.filter((obj: any) => {
      return obj.visibleMobile;
    });
  }
}
