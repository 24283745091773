import { Component, OnInit, Input } from '@angular/core';
import { IDynamicVideoIframe } from '../../interface/dynamics';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-dynamic-video-iframe',
  templateUrl: './dynamic-video-iframe.component.html',
  styleUrls: ['./dynamic-video-iframe.component.scss'],
})
export class DynamicVideoIframeComponent implements OnInit {
  @Input() item!: IDynamicVideoIframe;
  constructor(private sanitize: DomSanitizer) {}

  ngOnInit(): void {
    if (this.item) {
      const toArray = this.item.video_iframe.split(' ');
      const element = toArray.find((elem) => {
        return elem.startsWith('src');
      });
      const link = element?.split('=')[1].replace(/['"]+/g, '') as string;
      this.item.video_iframe = this.sanitize.bypassSecurityTrustResourceUrl(
        link
      ) as string;
    }
  }
}
