<div
  *ngIf="editorialPage$ | async as editorialPage"
  class="editorial-container"
>
  <div class="editorial-header">
    <div class="editorial-header-content">
      <h1 class="editorial-header-content-title">
        {{ editorialPage.attributes.title }}
      </h1>

      <p
        class="editorial-header-content-description"
        [innerHTML]="editorialPage.attributes.description | safeHtml"
        *ngIf="editorialPage.attributes.description"
      ></p>
    </div>
    <figure class="editorial-header-content-figure">
      <img
        *ngIf="editorialPage.attributes.image.data"
        mat-card-image
        [media]="editorialPage.attributes.image.data"
        [format]="'large'"
        class="editorial-header-image"
      />
    </figure>
  </div>
  <div class="editorial-main">
    <div class='dynamics'*ngFor="let item of editorialPage.attributes.dynamics">
         <app-dynamic-file
        *ngIf="item.__component === 'structure.file'"
        [item]="item"
      ></app-dynamic-file>
      <app-dynamic-text
        *ngIf="item.__component === 'structure.text'"
        [item]="item"
      ></app-dynamic-text>
      <app-dynamic-video
        *ngIf="item.__component === 'structure.video'"
        [item]="item"
      ></app-dynamic-video>
      <app-dynamic-image
        *ngIf="item.__component === 'structure.image'"
        [item]="item"
      ></app-dynamic-image>
      <app-dynamic-video-iframe
        *ngIf="item.__component === 'structure.video-iframe'"
        [item]="item"
      ></app-dynamic-video-iframe>
      <app-dynamic-accordion
        *ngIf="item.__component === 'structure.accordion'"
        [item]="item"
      >
      </app-dynamic-accordion>

    </div>

  </div>
</div>
