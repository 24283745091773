import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';

@Component({
  selector: 'app-menu-panel-mobile',
  templateUrl: './menu-panel-mobile.component.html',
  styleUrls: ['./menu-panel-mobile.component.scss'],
})
export class MenuPanelMobileComponent implements OnInit {
  @ViewChildren('menuChildren')
  menuChildren!: QueryList<MenuPanelMobileComponent>;
  constructor() {}
  @Input() labelRetour = '';
  @Input() items!: any[];
  @Input() visible = false;
  @Input() idPanel!: string;
  @Output() sidenavClose = new EventEmitter();
  @Output() closePanel = new EventEmitter();

  idPanelCourant!: string;
  ngOnInit(): void {}

  onSidenavClose(menu: MenuPanelMobileComponent): void {
    this.toggle();
    this.sidenavClose.emit();
  }
  public toggle(): void {
    this.visible = false;
    this.toggleChildren();
    this.closePanel.emit();
  }
  public toggleChildren(): void {
    this.menuChildren.toArray().map((elem) => {
      if (elem.idPanel === this.idPanelCourant) {
        elem.visible = false;
        this.idPanelCourant = '';
      }
    });
    this.visible = true;
  }
  showPanel(idPanel: string): void {
    this.menuChildren.toArray().map((elem) => {
      if (elem.idPanel === idPanel) {
        elem.visible = true;
        this.idPanelCourant = idPanel;
      }
    });

    this.visible = false;
  }
}
