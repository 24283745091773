<div class="information">
  <button
    mat-icon-button
    [mat-dialog-close]="true"
    class="information-header-button-close"
  >
    <mat-icon>close</mat-icon>
  </button>
  <div class="information-header">
    <h1 class="information-title">
      {{ popupInformation.data.attributes.title }}
    </h1>
  </div>

  <div class="information-body">
    <p [innerHTML]="popupInformation.data.attributes.content | safeHtml"></p>
    <figure class="figure-image-popup">
      <img
        role="img"
        [media]="popupInformation.data.attributes.image.data"
        class="information-image"
      />
    </figure>
  </div>
  <mat-dialog-actions class="informationActions">
    <button mat-button [mat-dialog-close]="true">
      {{ "action.close" | translate }}
    </button>
  </mat-dialog-actions>
</div>
