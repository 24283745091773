import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ILocalization, INews } from 'src/app/pages/actualites/interfaces/news';
import { NewsService } from 'src/app/pages/actualites/news.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as qs from 'qs';
import { Observable, of, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-actualite-detail',
  templateUrl: './actualite-detail.component.html',
  styleUrls: ['./actualite-detail.component.scss'],
  //ncapsulation: ViewEncapsulation.None,
})
export class ActualiteDetailComponent implements OnInit, OnDestroy {
  @Input() format?: string;
  public isLoadedAgenciesData = false;
  private subscriptionRemover = new Subject<void>();
  private params?: string;
  public article$?: Observable<INews>;
  public locale!: string;
  public localization!: Array<ILocalization>;

  constructor(
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private newsService: NewsService,
    private translate: TranslateService,
    private router: Router
  ) {
    this.locale = this.translate.currentLang;
    this.breadcrumbService.setItems([
      { label: 'menu.sInformer' },
      {
        label: 'menu.actualites',
        routerLink: 'ROUTES.actualites',
        visibleMobile: true,
      },
    ]);
  }

  ngOnInit(): void {
    this.route.params
      .pipe(takeUntil(this.subscriptionRemover))
      .subscribe((params) => (this.params = params.slug));

    this.router.events
      .pipe(
        takeUntil(this.subscriptionRemover),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.article$ = this.getArticles();
      });

    this.translate.onLangChange
      .pipe(takeUntil(this.subscriptionRemover))
      .subscribe((langChangeEvent: LangChangeEvent) => {
        this.locale = langChangeEvent.lang;
        const slug = this.localization.find(
          (data) => data.attributes.locale === this.locale
        )?.attributes.slug;

        this.router.navigate([
          '/',
          this.locale,
          this.translate.instant('ROUTES.actualites'),
          slug,
        ]);
      });
    this.article$ = this.getArticles();
  }

  ngOnDestroy(): void {
    this.subscriptionRemover.next();
    this.subscriptionRemover.complete();
  }

  private getArticles() {
    return this.newsService
      .retrieveArticles(
        qs.stringify({
          locale: this.locale,
          populate: {
            image: '*',
            localizations: {
              fields: ['slug', 'locale'],
            },
          },
          filters: {
            slug: {
              $eq: this.params,
            },
          },
        })
      )
      .pipe(
        switchMap((news) => {
          this.isLoadedAgenciesData = true;
          this.localization = news.data[0]?.attributes?.localizations?.data;
          return of(news.data[0]);
        })
      );
  }
}
