<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>
<p>&nbsp;</p>

<div style="background-color: #b1015c;
border-top-left-radius: 2rem;border-top-right-radius: 2rem;width: 95%;
height: 89px;text-align: center;font-size: 2rem;font-style: italic;color: white;" class="container">
    Erreur 404
</div>
<div style="background-color: #dfdfdf;border-radius: 2rem; width: 95%;margin-top: -1.5rem;" class="container">
    <p>&nbsp;</p>
    <p>&nbsp;</p>
<div style="display: flex;justify-content: space-between;flex-wrap: nowrap;align-content: space-between;align-items: center;flex-direction: column;">
    <h1>Page non trouvée</h1>
    <p class="text-align-center" style="width: fit-content;text-align: center;padding-top: 20px;">
        <strong>Il semblerait que la page que vous recherchez n’existe pas...</strong></p>
    <p>&nbsp;</p>

    <div style="display: flex;align-items: center;">
        <mat-icon aria-hidden="false" aria-label="fleche retour">arrow_back</mat-icon>
        <button mat-button aria-label="Retour à la page d'accueil" routerLink="/{{locale}}">Revenir à la page accueil</button>
    </div>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
</div>
</div>
