<div class="menuFooter">
  <mat-button-toggle-group
    name="menu"
    aria-label="menu"
    [(ngModel)]="selectedMenu"
  >
    <mat-button-toggle
      value="home"
      routerLink="/{{ locale }}"
      (click)="navigateTo('home')"
    >
      <div class="menuIconText">
        <mat-icon>home</mat-icon>
        <span translate>raccourcis.accueil</span>
      </div></mat-button-toggle
    >
    <mat-button-toggle
      value="itineraires"
      routerLink="{{ 'ROUTES.itineraires' | translate }}"
      (click)="navigateTo('itineraires')"
      ><div class="menuIconText">
        <mat-icon>transfer_within_a_station</mat-icon>
        <span translate>menu.itineraires</span>
      </div></mat-button-toggle
    >
    <mat-button-toggle
      value="mon-compte"
      routerLink="{{ 'ROUTES.mon-compte' | translate }}"
      (click)="navigateTo('mon-compte')"
    >
      <div class="menuIconText">
        <mat-icon>person</mat-icon>
        <span translate>menu.monEspace</span>
      </div></mat-button-toggle
    >

    <mat-button-toggle value="search" (click)="showSearchModal()">
      <div class="menuIconText">
        <mat-icon>search</mat-icon>
        <span translate>raccourcis.rechercher</span>
      </div></mat-button-toggle
    >
  </mat-button-toggle-group>
</div>
