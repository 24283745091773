import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  LocalizeParser,
  LocalizeRouterModule,
  LocalizeRouterSettings,
  ManualParserLoader,
} from '@gilsdav/ngx-translate-router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { AppMainComponent } from './app-main.component';
import { AppNotFoundComponent } from './app-not-found.component';
import { ActualiteDetailComponent } from './pages/actualites/actualite-detail/actualite-detail.component';
import { ItinerairesImprimerComponent } from './pages/itineraires/itineraires-imprimer/itineraires-imprimer.component';
import { TransportOnDemandAllobusComponent } from './pages/transport-on-demand-info/transport-on-demand-allobus/transport-on-demand-allobus.component';
import { TransportOnDemandFilorComponent } from './pages/transport-on-demand-info/transport-on-demand-filor/transport-on-demand-filor.component';
const routes: Routes = [
  {
    path: '',
    component: AppMainComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/home-page/home-page.module').then(
            (m) => m.HomePageModule
          ),
      },
      {
        path: 'se-deplacer',
        loadChildren: () =>
          import('./pages/me-deplacer/me-deplacer.module').then(
            (m) => m.MeDeplacerModule
          ),
      },
      {
        path: 'actualites',
        loadChildren: () =>
          import('./pages/actualites/actualites.module').then(
            (m) => m.ActualitesModule
          ),
      },
      { path: 'actualites/:slug', component: ActualiteDetailComponent },
      {
        path: 'itineraires',
        loadChildren: () =>
          import('./pages/itineraires/itineraires.module').then(
            (m) => m.ItinerairesModule
          ),
      },
      {
        path: 'legal-information',
        loadChildren: () =>
          import('./pages/infos-legales/infos-legales.module').then(
            (m) => m.InfosLegalesModule
          ),
      },
      {
        path: 'mon-compte',
        loadChildren: () =>
          import('./pages/connexion/connexion.module').then(
            (m) => m.ConnexionModule
          ),
      },
      {
        path: 'bike',
        loadChildren: () =>
          import('./pages/bike/bike.module').then((m) => m.BikeModule),
      },
      {
        path: 'taxi',
        loadChildren: () =>
          import('./pages/taxi/taxi.module').then((m) => m.TaxiModule),
      },
      {
        path: 'car',
        loadChildren: () =>
          import('./pages/car/car.module').then((m) => m.CarModule),
      },
      {
        path: 'walk',
        loadChildren: () =>
          import('./pages/walk/walk.module').then((m) => m.WalkModule),
      },
      {
        path: 'faq',
        loadChildren: () =>
          import('./pages/faq/faq.module').then((m) => m.FaqModule),
      },
      {
        path: 'contact',
        loadChildren: () =>
          import('./pages/contact/contact.module').then((m) => m.ContactModule),
      },
      {
        path: 'about',
        loadChildren: () =>
          import('./pages/about/about.module').then((m) => m.AboutModule),
      },
      {
        path: 'agencies',
        loadChildren: () =>
          import('./pages/agencies/agencies.module').then(
            (m) => m.AgenciesModule
          ),
      },
      {
        path: 'subscribers',
        loadChildren: () =>
          import('./pages/subscriber/subscriber.module').then(
            (m) => m.SubscriberModule
          ),
      },
      {
        path: 'shop',
        loadChildren: () =>
          import('./pages/shop/shop.module').then((m) => m.ShopModule),
      },
      {
        path: 'fine',
        loadChildren: () =>
          import('./pages/fine/fine.module').then((m) => m.FineModule),
      },
      {
        path: 'payment',
        loadChildren: () =>
          import('./pages/payment/payment.module').then((m) => m.PaymentModule),
      },
      {
        path: 'cookies',
        loadChildren: () =>
          import('./pages/cookies/cookies.module').then((m) => m.CookiesModule),
      },
      {
        path: 'pro-rate',
        loadChildren: () =>
          import('./pages/pro-rate/pro-rate.module').then(
            (m) => m.ProRateModule
          ),
      },
      {
        path: 'reserver-tad-allobus',
        loadChildren: () => 
          import('./pages/reserver-tad-allobus/reserver-tad-allobus.module').then(
            (m) => m.ReserverTadAllobusModule
          )
      },
      {
        path: 'pro-company',
        loadChildren: () =>
          import('./pages/pro-company/pro-company.module').then(
            (m) => m.ProCompanyModule
          ),
      },
      {
        path: 'site-map',
        loadChildren: () =>
          import('./pages/site-map/site-map.module').then(
            (m) => m.SiteMapModule
          ),
      },
      {
        path: 'accessibility',
        loadChildren: () =>
          import('./pages/accessibility/accessibility.module').then(
            (m) => m.AccessibilityModule
          ),
      },
      {
        path: 'privacy',
        loadChildren: () =>
          import('./pages/privacy/privacy.module').then((m) => m.PrivacyModule),
      },
      {
        path: 'mentions-legales',
        redirectTo: 'privacy'
      },
      {
        path: 'mail',
        loadChildren: () =>
          import('./pages/mail/mail.module').then((m) => m.MailModule),
      },
      {
        path: 'phone',
        loadChildren: () =>
          import('./pages/phone/phone.module').then((m) => m.PhoneModule),
      },
      {
        path: 'phone-contact',
        loadChildren: () =>
          import('./pages/phone-contact/phone-contact.module').then((m) => m.PhoneContactModule),
      },
      {
        path: 'public-transport-hourly',
        loadChildren: () =>
          import(
            './pages/public-transport-hourly/public-transport-hourly.module'
          ).then((m) => m.PublicTransportHourlyModule),
      },      
      {
        path: 'sortie-scolaire',
        loadChildren: () =>
          import(
            './pages/sortie-scolaire/sortie-scolaire.module'
          ).then((m) => m.SortieScolaireModule),
      },
      {
        path: 'all-doc',
        loadChildren: () => 
          import(
            './pages/all-doc/all-doc.module'
          ).then((m) => m.AllDocModule)
      },
      {
        path: 'public-transport-map2',
        loadChildren: () =>
          import(
            './pages/public-transport-map2/public-transport-map2.module'
          ).then((m) => m.PublicTransportMap2Module),
      },
      {
        path: 'public-transport-info',
        loadChildren: () =>
          import(
            './pages/public-transport-info/public-transport-info.module'
          ).then((m) => m.PublicTransportInfoModule),
      },
      {
        path: 'transport-on-demand-map',
        loadChildren: () =>
          import(
            './pages/transport-on-demand-map/transport-on-demand-map.module'
          ).then((m) => m.TransportOnDemandMapModule),
      },
      {
        path: 'transport-on-demand-info',
        loadChildren: () =>
          import(
            './pages/transport-on-demand-info/transport-on-demand-info.module'
          ).then((m) => m.TransportOnDemandInfoModule),
      },
      {
        path: 'transport-on-demand-book',
        loadChildren: () =>
          import(
            './pages/transport-on-demand-book/transport-on-demand-book.module'
          ).then((m) => m.TransportOnDemandBookModule),
      },
      {
        path: 'transport-on-demand-book-old',
        loadChildren: () =>
          import(
            './pages/transport-on-demand-book-old/transport-on-demand-book-old.module'
          ).then((m) => m.TransportOnDemandBookOldModule),
      },
      {
        path: 'carte-interactive',
        loadChildren: () =>
          import('./pages/carte-interactive/carte-interactive.module').then(
            (m) => m.CarteInteractiveModule
          ),
      },
      {
        path: 'lovelo-libre-service',
        loadChildren: () =>
          import('./pages/levelo-libre-service/levelo-libre-service.module').then(
            (m) => m.LeveloLibreServiceModule
          ),
      },
      {
        path: 'lovelo-stationnement',
        loadChildren: () =>
          import('./pages/levelo-stationnement/levelo-stationnement.module').then(
            (m) => m.LeveloStationnementModule
          ),
      },
      {
        path: 'lovelo-location-longue-duree',
        loadChildren: () =>
          import('./pages/lovelo-long-rental/lovelo-long-rental.module').then(
            (m) => m.LoveloLongRentalModule
          ),
      },
      {
        path: 'titre-sms-ou-m-ticket',
        loadChildren: () =>
          import('./pages/titre-sms/titre-sms.module').then(
            (m) => m.TitreSmsModule
          ),
      },
      {
        path: 'le-train-nomad-avec-votre-abonnement-astuce',
        loadChildren: () =>
          import('./pages/train-nomade/train-nomade.module').then(
            (m) => m.TrainNomadeModule
          ),
      },
      {
        path: 'prime-transport',
        loadChildren: () =>
          import('./pages/prime-transport/prime-transport.module').then(
            (m) => m.PrimeTransportModule
          ),
      },
      {
        path: 'where-to-buy-tickets',
        loadChildren: () =>
          import('./pages/where-to-buy/where-to-buy.module').then(
            (m) => m.WhereToBuyModule
          ),
      },
      {
        path: 'titre-gratuit-le-samedi',
        loadChildren: () =>
          import('./pages/free-tickets-saturday/free-tickets-saturday.module').then(
            (m) => m.FreeTicketsSaturdayModule
          ),
      },
      {
        path: 'handistuce',
        loadChildren: () =>
          import('./pages/handistuce/handistuce.module').then(
            (m) => m.HandistuceModule
          ),
      },
      {
        path: 'regler-une-amende',
        loadChildren: () =>
          import('./pages/regler-une-amende/regler-une-amende.module').then(
            (m) => m.ReglerUneAmendeModule
          ),
      },
      {
        path: 'service-de-bus-interurbains',
        loadChildren: () =>
          import('./pages/service-de-bus-interurbains/service-de-bus-interurbains.module').then(
            (m) => m.ServiceDeBusInterurbainsModule
          ),
      },
      {
        path: 'voyager-en-train',
        loadChildren: () =>
          import('./pages/voyager-en-train/voyager-en-train.module').then(
            (m) => m.VoyagerEnTrainModule
          ),
      },
      {
        path: 'abonnement-atoumod',
        loadChildren: () =>
          import('./pages/abonnement-atoumod/abonnement-atoumod.module').then(
            (m) => m.AbonnementAtoumodModule
          ),
      },
      {
        path: 'carte-astuce',
        loadChildren: () =>
          import('./pages/carte-astuce/carte-astuce.module').then(
            (m) => m.CarteAstuceModule
          ),
      },
      {
        path: 'titre-de-transport',
        loadChildren: () =>
          import('./pages/titre-de-transport/titre-de-transport.module').then(
            (m) => m.TitreDeTransportModule
          ),
      },
      {
        path: 'abonnements',
        loadChildren: () =>
          import('./pages/abonnements/abonnements.module').then(
            (m) => m.AbonnementsModule
          ),
      },
      {
        path: 'titre-365-jours-demotorisation',
        loadChildren: () =>
          import('./pages/titre-demotorisation/titre-demotorisation.module').then(
            (m) => m.TitreDemotorisationModule
          ),
      },
      {
        path: 'rss',
        loadChildren: () =>
          import('./pages/rss/rss.module').then(
            (m) => m.RssModule
          ),
      },
      {
        path: 'cgu',
        loadChildren: () =>
          import('./pages/cgu/cgu.module').then(
            (m) => m.CguModule
          ),
      },
      {
        path: 'taxi-book',
        loadChildren: () =>
          import('./pages/taxi-book/taxi-book.module').then(
            (m) => m.TaxiBookModule
          ),
      },
      {
        path: 'taxi-info',
        loadChildren: () =>
          import('./pages/taxi-info/taxi-info.module').then(
            (m) => m.TaxiInfoModule
          ),
      },
      {
        path: 'covoiturage',
        loadChildren: () =>
          import('./pages/car-carpooling/car-carpooling.module').then(
            (m) => m.CarCarpoolingModule
          ),
      },
      {
        path: 'car-park',
        loadChildren: () =>
          import('./pages/car-park/car-park.module').then(
            (m) => m.CarParkModule
          ),
      },
      {
        path: 'beneficiaries',
        loadChildren: () =>
          import('./pages/beneficiaries/beneficiaries.module').then(
            (m) => m.BeneficiariesModule
          ),
      },
      {
        path: 'ctw',
        loadChildren: () =>
          import('./pages/ctw/ctw.module').then((m) => m.CtwModule),
      },
      {
        path: 'search',
        loadChildren: () =>
          import('./pages/search/search.module').then((m) => m.SearchModule),
      },
      {
        path: 'tariff-information',
        loadChildren: () =>
          import('./pages/tariff-information/tariff-information.module').then(
            (m) => m.TariffInformationModule
          ),
      },
      {
        path: 'rate',
        loadChildren: () =>
          import('./pages/rate/rate.module').then((m) => m.RateModule),
      },
      {
        path: 'survey',
        loadChildren: () =>
          import('./pages/survey/survey.module').then((m) => m.SurveyModule),
      },
      {
        path: 'travel',
        loadChildren: () =>
          import('./pages/travel/travel.module').then((m) => m.TravelModule),
      },
      {
        path: 'guides',
        loadChildren: () =>
          import('./pages/guides/guides.module').then((m) => m.GuidesModule),
      },
      {
        path: 'descent-on-demand',
        loadChildren: () =>
          import('./pages/descent-on-demand/descent-on-demand.module').then(
            (m) => m.DescentOnDemandModule
          ),
      },
      {
        path: 'proximity-rules',
        loadChildren: () =>
          import('./pages/proximity-rules/proximity-rules.module').then(
            (m) => m.ProximityRulesModule
          ),
      },
      {
        path: 'how-to-react',
        loadChildren: () =>
          import('./pages/how-to-reaction/how-to-react.module').then(
            (m) => m.HowToReactModule
          ),
      },
      {
        path: 'video-protection',
        loadChildren: () =>
          import('./pages/video-protection/video-protection.module').then(
            (m) => m.VideoProtectionModule
          ),
      },
      {
        path: 'calypso',
        loadChildren: () =>
          import('./pages/calypso/calypso.module').then((m) => m.CalypsoModule),
      },
      {
        path: 'mot-de-passe-oublie',
        loadChildren: () =>
          import('./pages/forget-password/forget-password.module').then(
            (m) => m.ForgetPasswordModule
          ),
      },
      {
        path: 'confirmation-email',
        loadChildren: () =>
          import('./pages/confirm-email/confirm-email.module').then(
            (m) => m.ConfirmEmailModule
          ),
      },
      {
        path: 'e-boutique',
        loadChildren: () =>
          import('./pages/eshop/eshop.module').then((m) => m.EshopModule),
      },
      {
        path: 'applis-mobile-astuce',
        loadChildren: () =>
          import('./editorial-pages/applis-mobile-astuce/applis-mobile-astuce.module').then(
            (m) => m.ApplisMobileAstuceModule
          ),
      },
      {
        path: 'transport-on-demand-allobus',
        component: TransportOnDemandAllobusComponent,
      },
      {
        path: 'transport-on-demand-filor',
        component: TransportOnDemandFilorComponent
      }
    ],
  },
  { path: '404', component: AppNotFoundComponent },
  { path: 'itineraires-imprimer', component: ItinerairesImprimerComponent},
  { path: '**', redirectTo: '/404' },
];

const localizeRouterParserFactory = (
  translate: TranslateService,
  location: Location,
  settings: LocalizeRouterSettings
) => {
  settings.cacheName = environment.language.localeKey;
  return new ManualParserLoader(translate, location, settings, ['en', 'fr']);
};
@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: localizeRouterParserFactory,
        deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient],
      },

      /*defaultLangFunction: (languages, cachedLang, browserLang) => {
        if (browserLang) {
          if (languages.includes(browserLang)) {
            return browserLang;
          }
        }
        return languages[0];
      },
      useCachedLang: false,*/
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
