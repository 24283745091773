import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'articleDate',
})
export class ArticleDate implements PipeTransform {
  constructor() {}

  transform(articleDate: string, locale: string): any {
    const date = new Date(articleDate);
    return date.toLocaleDateString(locale);
  }
}
