import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NewsService } from 'src/app/pages/actualites/news.service';
import * as qs from 'qs';
import { Observable, of, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { INews } from 'src/app/pages/actualites/interfaces/news';
import { Router } from '@angular/router';
import { LayoutService } from 'src/app/layout/layout.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit, OnDestroy {
  public locale: string;
  public search: string = '';
  public articles$!: Observable<Array<INews>>;
  private subscriptionRemover = new Subject<void>();

  constructor(
    private newsService: NewsService,
    private translate: TranslateService,
    private router: Router,
    private layout: LayoutService
  ) {
    this.locale = this.translate.currentLang;

    this.translate.onLangChange
      .pipe(takeUntil(this.subscriptionRemover))
      .subscribe(
        (langChangeEvent: LangChangeEvent) => (
          (this.locale = langChangeEvent.lang),
          (this.articles$ = this.getArticles())
        )
      );

    router.events.subscribe(() => {
      this.search = '';
    });
  }

  ngOnInit(): void {}

  onSubmit(event: Event) {
    event.preventDefault();
    this.router.navigate(
      ['/', this.locale, this.translate.instant('ROUTES.search')],
      {
        queryParams: { q: this.search },
      }
    );
    this.search = '';
    this.layout.setSearchModalDisplayed(false);
  }

  private getArticles() {
    return this.newsService
      .retrieveArticles(
        qs.stringify({
          locale: this.locale,
          _q: this.search,
        })
      )
      .pipe(
        switchMap((news) => {
          return of(news.data);
        })
      );
  }

  ngOnDestroy(): void {
    this.subscriptionRemover.next();
    this.subscriptionRemover.complete();
  }
}
