import { Component, OnDestroy } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { LayoutService } from '../layout.service';
import { BreadcrumbItem } from '../modeles/breadcrumb-item';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnDestroy {
  subscription: Subscription;
  private subscriptionRemover = new Subject<void>();

  public locale!: string;
  items: BreadcrumbItem[] = [];

  home: BreadcrumbItem;

  constructor(
    public breadcrumbService: BreadcrumbService,
    private translate: TranslateService,
    private layout: LayoutService
  ) {
    this.locale = this.translate.currentLang;

    this.translate.onLangChange
      .pipe(takeUntil(this.subscriptionRemover))
      .subscribe(
        (langChangeEvent: LangChangeEvent) =>
          (this.locale = langChangeEvent.lang)
      );

    this.subscription = breadcrumbService.itemsHandler.subscribe((response) => {
      this.items = response;
    });

    this.home = { icon: 'home', routerLink: '/' };
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  navigateToAccueil() {
    this.layout.setSelectedMenu('home');
  }
}
