import { Component, OnInit, Input } from '@angular/core';
import { IDynamicFile } from '../../interface/dynamics';
@Component({
  selector: 'app-dynamic-file',
  templateUrl: './dynamic-file.component.html',
  styleUrls: ['./dynamic-file.component.scss'],
})
export class DynamicFileComponent implements OnInit {
  @Input() item!: IDynamicFile;
  constructor() {}

  ngOnInit(): void {}
}
