<form
  (submit)="onSubmit($event)"
  class="search-form"
  aria-label="Formulaire de recherche"
>
  <input
    type="text"
    matInput
    name="search"
    role="searchbox"
    placeholder="{{ 'raccourcis.rechercher' | translate }}"
    autocomplete="off"
    floatLabel="never"
    [(ngModel)]="search"
    class="search-input"
    id="searchInput"
    aria-label="Recherche par mot clés"
  />
  <button type="submit" aria-label="Rechercher" class="button-search">
    <mat-icon matSuffix class="search-icon">search</mat-icon>
  </button>
</form>
