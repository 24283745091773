import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (
      request.method === 'GET' &&
      request.url.startsWith(environment.baseUrlApiMgt) // seulement pour les appels strapi
    ) {
      request = request.clone({
        headers: request.headers.set(
          environment.cleApiName,
          environment.cleApi
        ),
      });
    }
    return next.handle(request);
  }
}
