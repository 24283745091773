import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { environment } from '../../../../src/environments/environment';
import { IEditorialPageResponse } from './interfaces/EditorialPage';

@Injectable({
  providedIn: 'root',
})
export class EditorialPageService {
  constructor(private httpClient: HttpClient) {}

  retrieveEditorialPage(options?: string): Observable<IEditorialPageResponse> {
    return this.httpClient
      .get<IEditorialPageResponse>(
        `${environment.baseUrlApiMgt}/editorial-pages${
          options ? `?${options}` : ''
        }`
      )
      .pipe(
        timeout(environment.timeoutWebServiceCMS),
        map((data: IEditorialPageResponse) => {
          return data;
        }),
        catchError((error) => {
          return [];
        })
      );
  }
}
