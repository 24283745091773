<mat-progress-bar
  *ngIf="isLoadedAgenciesData === false"
  mode="indeterminate"
></mat-progress-bar>

<article *ngIf="article$ | async as article" class="article-container">
  <!-- header container-->
  <div class="article-header">
    <div class="article-header-content">
      <!--check the locale the display the locale in good formats-->
      <p class="article-header-content-publishedAt" translate fxHide.xs>
        <span translate>article.publish </span
        >{{ article.attributes.publishedAt | articleDate: locale }}
      </p>

      <h1 class="article-header-content-title">
        {{ article.attributes.title }}
      </h1>
      <p
        class="article-header-content-publishedAt"
        translate
        fxHide.sm
        fxHide.md
        fxHide.lg
        fxHide.xl
      >
        <span translate>article.publish </span
        >{{ article.attributes.publishedAt | articleDate: locale }}
      </p>

      <p class="article-header-content-resume">
        {{ article?.attributes?.description }}
      </p>
    </div>
    <figure>
      <img
        mat-card-image
        [media]="article.attributes.image.data"
        [format]="'large'"
        class="article-header-image"
      />
    </figure>
  </div>
  <!-- rich text container -->
  <div class="article-content">
    <hr class="article-divider" />
    <div
      class="article-content-richtext"
      *ngIf="article.attributes.text"
      [innerHTML]="article.attributes.text | safeHtml"
    ></div>
    <hr class="article-divider" />
    <div class="separator"></div>
  </div>
</article>
