import { Component, OnInit, Input } from '@angular/core';
import { IDynamicImage } from '../../interface/dynamics';

@Component({
  selector: 'app-dynamic-image',
  templateUrl: './dynamic-image.component.html',
  styleUrls: ['./dynamic-image.component.scss'],
})
export class DynamicImageComponent implements OnInit {
  @Input() item!: IDynamicImage;
  constructor() {}

  ngOnInit(): void {}
}
