import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import menuJson from '../../../assets/configuration/menu.json';
import { LayoutService } from '../layout.service';
import { eventCtwMember } from 'src/app/shared/interface/ctwEnum';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() public sidenavToggle = new EventEmitter();
  @Output() public sidenavClose = new EventEmitter();

  private subscriptionRemover = new Subject<void>();

  language!: string;
  currentMenuPanel!: string;

  public items = menuJson;

  constructor(
    private translate: TranslateService,
    private storage: StorageService,
    private layout: LayoutService,
    private localizeRouterService: LocalizeRouterService,
    private router: Router,
  ) {
    this.language = this.translate.currentLang;

    this.translate.onLangChange
      .pipe(takeUntil(this.subscriptionRemover))
      .subscribe(
        (langChangeEvent: LangChangeEvent) =>
          (this.language = langChangeEvent.lang)
      );
  }

  ngOnInit(): void {}

  public onToggleSidenav = () => {
    this.sidenavToggle.emit();
  };

  public onSidenavClose = () => {
    this.sidenavClose.emit();
  };
  public switchLocale(language: string): void {
    this.language = language;
    this.layout.switchLocale(this.language);
  }

  ngOnDestroy(): void {
    this.subscriptionRemover.next();
    this.subscriptionRemover.complete();
  }

  menuOpened(menuId: string): void {
    this.currentMenuPanel = menuId;
  }

  menuClosed(menuId: string): void {
    this.currentMenuPanel = '';
  }
  showMap(event: any) {
    event.stopPropagation();
  }

  raisedEvent(event: any) : void{
    // Le bouton renvoie un raisedEvent "MemberHome" afin de rediriger sur l'espace membre si l'utilisateur est connecté.
    if(event.detail?.action === eventCtwMember.MemberHome){
        // prepare the url and navigate
        const url = this.localizeRouterService.translateRoute('/mon-compte');
        const params = {
          context: eventCtwMember.MemberHome,
        }
        this.router.navigate([url], {queryParams: params});
    }
    // Le bouton renvoie un raisedEvent "LoginSigninForm" avec selectedTab=signIn si l'utilisateur n'est pas connecté
    // dans le cas où le SSO externe n'est pas implémenté
    else if(event.detail?.action === eventCtwMember.LoginSigninForm){
      // prepare the url, then navigate
      const url = this.localizeRouterService.translateRoute('/mon-compte');
      const queryParams = {
        context : eventCtwMember.LoginSigninForm,
        selectedTab: event.detail?.parameters?.get('selectedTab')
      }
      this.router.navigate([url], { queryParams });
    }
  }
}
