import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { LayoutService } from 'src/app/layout/layout.service';
import { eventCtwJourneyPlanner, eventCtwJourneyPlannerPrint } from 'src/app/shared/interface/ctwEnum';

@Component({
  selector: 'app-itineraires-imprimer',
  templateUrl: './itineraires-imprimer.component.html',
  styleUrls: []
})
export class ItinerairesImprimerComponent implements OnInit, OnDestroy {
  private subscriptionRemover = new Subject<void>();

  // context par défault de la page
  context: string = eventCtwJourneyPlanner.Print;

  // les parametres de la requete
  cacheKey: string = "";
  tripIndex?: number;
  newTab?: boolean;

  constructor(
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private localizeRouterService: LocalizeRouterService,
    private cdr: ChangeDetectorRef  // Inject ChangeDetectorRef
  ) { 
    // subscrive to translate service
    this.translate.onLangChange
      .pipe(takeUntil(this.subscriptionRemover))
      .subscribe((langChangeEvent: LangChangeEvent) => {
        localizeRouterService.changeLanguage(langChangeEvent.lang, {}, false);
    });
  }

  ngOnInit(): void {
    // Subscribe to query parameters
    this.activatedRoute.queryParams
    .pipe(
      takeUntil(this.subscriptionRemover),
      tap((queryParams) => {
        this.cacheKey = queryParams['cacheKey'];
        this.tripIndex = queryParams['tripIndex'];
        this.cdr.detectChanges();  // Trigger change detection
      })
    )
    .subscribe();
  }

  raisedEvent(event: any) : void{
    if(event.detail?.action === eventCtwJourneyPlannerPrint.Accessibility){
      // prepare the url, then navigate
      const url = this.localizeRouterService.translateRoute('/accessibilité')
      // detect the start of the url automatically, then append the rest of the url
      const urlObject = new URL(url.toString(), window.location.origin);
      // Open the URL in a new tab
      window.open(urlObject, '_blank');
    }
  }

  ngOnDestroy(): void {
    this.subscriptionRemover.next();
    this.subscriptionRemover.complete();
  }

}