import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { INewsReponse } from 'src/app/pages/actualites/interfaces/news';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NewsService {
  articlesError = new Subject<string>();

  constructor(private httpClient: HttpClient) {}

  retrieveArticles(options?: string): Observable<INewsReponse> {
    return this.httpClient
      .get<INewsReponse>(
        `${environment.baseUrlApiMgt}/articles${options ? `?${options}` : ''}`
      )
      .pipe(
        timeout(environment.timeoutWebServiceCMS),
        map((data: INewsReponse) => {
          return data;
        }),
        catchError((error) => {
          this.articlesError.next('error.articles-' + error.status); // Envoi le message d'erreur à tous les abonnés
          return [];
        })
      );
  }
}
