import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IFooterResponse } from 'src/app/layout/footer-dynamic/interfaces/footer';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  constructor(private httpClient: HttpClient) {}

  getFooterContent(options?: string): Observable<IFooterResponse> {
    return this.httpClient.get<IFooterResponse>(
      `${environment.baseUrlApiMgt}/footer${options ? `?${options}` : ''}`
    );
  }
}
