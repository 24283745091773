<ng-container>
  <ul class="nav-list-mobile">
    <div>
      <li class="header-menu" *ngIf="visible">
        <button
          mat-mini-fab
          aria-label="Retour menu principal"
          (click)="toggle()"
        >
          <mat-icon>chevron_left</mat-icon>
        </button>
        <span class="header-menu-title" translate>{{ labelRetour }}</span>
      </li>
      <div *ngFor="let item of items; let i = index">
        <li class="item-menu-mobile" *ngIf="visible">
          <button
            *ngIf="item.children === undefined"
            mat-button
            routerLink="{{ item.routerLink | translate }}"
            (click)="onSidenavClose(this)"
          >
            <span translate>{{ item.label }}</span>
          </button>
          <button
            *ngIf="item.children !== undefined && item.children.length"
            mat-button
            class="subMenu"
            (click)="showPanel(item.idPanel)"
          >
            <span translate>{{ item.label }}</span>
          </button>
        </li>

        <app-menu-panel-mobile
          #menuChildren
          *ngIf="item.children !== undefined && item.children.length"
          [idPanel]="item.idPanel"
          [labelRetour]="item.label"
          [items]="item.children"
          [visible]="false"
          (sidenavClose)="onSidenavClose(this)"
          (closePanel)="toggleChildren()"
        ></app-menu-panel-mobile>
      </div>
    </div>
  </ul>
</ng-container>
