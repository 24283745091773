import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BreadcrumbItem } from '../layout/modeles/breadcrumb-item';

@Injectable({ providedIn: 'root' })
export class BreadcrumbService {
  private itemsSource = new Subject<BreadcrumbItem[]>();

  itemsHandler = this.itemsSource.asObservable();

  setItems(items: BreadcrumbItem[]): void {
    this.itemsSource.next(items);
  }

  public getItems(): Subject<BreadcrumbItem[]> {
    return this.itemsSource;
  }
}
