import { Component, OnInit, Input } from '@angular/core';
import { IDynamicAccordion } from '../../interface/dynamics';

@Component({
  selector: 'app-dynamic-accordion',
  templateUrl: './dynamic-accordion.component.html',
  styleUrls: ['./dynamic-accordion.component.scss']
})
export class DynamicAccordionComponent implements OnInit {
  @Input() item?: IDynamicAccordion;

  constructor() { }

  ngOnInit(): void {
  }

}
