<div class="footer" *ngIf="footerContent$ | async as footerContent">
  <div class="footer-dynamic">
    <!-- Contact card-->
    <div
      class="footer-dynamic-contact"
      *ngIf="footerContent.attributes.contact.visible"
    >
      <h2 class="footer-dynamic-contact-title">
        {{ footerContent.attributes.contact.title }}
      </h2>
      <div class="footer-dynamic-contact-content">
        <a
          class="footer-dynamic-contact-content-attributes"
          *ngFor="
            let footerContent of footerContent.attributes.contact.contact_links
          "
          [routerLink]="footerContent.route"
        >
          <figure class="footer-dynamic-contact-content-attributes-figure">
            <img
              mat-card-image
              [media]="footerContent.icon.data"
              [format]="format"
              class="footer-dynamic-contact-content-attributes-image"
            />
          </figure>
          <p>{{ footerContent.label }}</p>
        </a>
      </div>
    </div>

    <!-- Information card-->
    <div
      class="footer-dynamic-informations"
      *ngIf="footerContent.attributes.informations.visible"
    >
      <h2 class="footer-dynamic-informations-title">
        {{ footerContent.attributes.informations.title }}
      </h2>
      <div class="footer-dynamic-informations-content">
        <a
          class="footer-dynamic-contact-informations-attributes"
          *ngFor="
            let footerContent of footerContent.attributes.informations
              .information_links
          "
          [routerLink]="footerContent.route"
        >
          <p>{{ footerContent.label }}</p>
        </a>
      </div>
    </div>

    <!-- Social card-->
    <div
      class="footer-dynamic-social"
      *ngIf="footerContent.attributes.social.visible"
    >
      <h2 class="footer-dynamic-social-title">
        {{ footerContent.attributes.social.title }}
      </h2>
      <div class="footer-dynamic-social-content">
        <a
          class="footer-dynamic-social-content-attributes"
          *ngFor="
            let footerContent of footerContent.attributes.social.social_links
          "
          href="{{ footerContent.link }}"
          target="_blank"
        >
          <figure>
            <img
              mat-card-image
              [media]="footerContent.icon.data"
              [format]="format"
              class="footer-dynamic-social-content-attributes-image"
            />
          </figure>
        </a>
      </div>
    </div>

    <!-- Application card-->
    <div
      class="footer-dynamic-application"
      *ngIf="footerContent.attributes.application.visible"
    >
      <h2 class="footer-dynamic-application-title">
        {{ footerContent.attributes.application.title }}
      </h2>
      <div class="footer-dynamic-application-content">
        <a
          class="footer-dynamic-application-content-attributes"
          *ngFor="
            let footerContent of footerContent.attributes.application
              .application_links
          "
          href="{{ footerContent.link }}"
        >
          <figure>
            <img
              mat-card-image
              [media]="footerContent.icon.data"
              [format]="format"
              class="footer-dynamic-application-content-attributes-image"
            />
          </figure>
        </a>
      </div>
    </div>
  </div>
</div>
