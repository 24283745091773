import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { BandeauAlertesComponent } from './bandeau-alertes/bandeau-alertes.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { FooterDynamicComponent } from './footer-dynamic/footer-dynamic.component';
import { FooterFixComponent } from './footer-fix/footer-fix.component';
import { FooterMapComponent } from './footer-map/footer-map.component';
import { HeaderComponent } from './header/header.component';
import { LayoutService } from './layout.service';
import { MenuFooterComponent } from './menu-footer/menu-footer.component';
import { MenuPanelMobileComponent } from './menu-panel-mobile/menu-panel-mobile.component';
import { MenuPanelComponent } from './menu-panel/menu-panel.component';
import { SideNavListComponent } from './side-nav-list/side-nav-list.component';

import { BreadcrumbMobilePipe } from '../pipes/breadcrumbMobile.pipe';
@NgModule({
  declarations: [
    HeaderComponent,
    FooterFixComponent,
    BreadcrumbComponent,
    SideNavListComponent,
    FooterMapComponent,
    BandeauAlertesComponent,
    MenuPanelComponent,
    MenuFooterComponent,
    FooterDynamicComponent,
    MenuPanelMobileComponent,
    BreadcrumbMobilePipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, SharedModule, FormsModule],
  exports: [
    HeaderComponent,
    FooterFixComponent,
    FooterMapComponent,
    BreadcrumbComponent,
    SideNavListComponent,
    BandeauAlertesComponent,
    MenuPanelComponent,
    MenuFooterComponent,
    FooterDynamicComponent,
  ],
  providers: [LayoutService],
})
export class LayoutModule {}
