<mat-menu #menu class="cssMrn">
  <ng-container *ngFor="let item of items">
    <button
      [attr.aria-label]="item.name"
      *ngIf="!item.children; else branch"
      mat-menu-item
      tabindex="0"
      [routerLink]="item.routerLink ? [item.routerLink | translate] : []"
      class="cssMrnSubMenu"
    >
      <mat-icon
        class="mat-icon-menu"
        *ngIf="item.icon !== undefined && item.icon !== ''"
        >{{ item.icon }}</mat-icon
      >
      {{ item.label | translate }}
    </button>
    <ng-template #branch>
      <button
        aria-expanded="false"
        mat-menu-item
        [matMenuTriggerFor]="innerPanel.menu"
        class="cssMrnSubMenu"
      >
        <mat-icon
          class="mat-icon-menu"
          *ngIf="item.icon !== undefined && item.icon !== ''"
          >{{ item.icon }}</mat-icon
        >
        {{ item.label | translate }}
      </button>
      <app-menu-panel #innerPanel [items]="item.children"></app-menu-panel>
    </ng-template>
  </ng-container>
</mat-menu>
