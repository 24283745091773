import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-map',
  templateUrl: './footer-map.component.html',
  styleUrls: ['./footer-map.component.scss'],
})
export class FooterMapComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
