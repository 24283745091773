import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil, tap } from 'rxjs';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';

@Component({
  selector: 'app-transport-on-demand-allobus',
  templateUrl: './transport-on-demand-allobus.component.html',
  styleUrls: ['./transport-on-demand-allobus.component.scss']
})
export class TransportOnDemandAllobusComponent implements OnInit {

  private subscriptionRemover = new Subject<void>();
  constructor(
    private breadcrumbService: BreadcrumbService,
    private translate: TranslateService,
    private router: Router,
    private localizeRouterService: LocalizeRouterService,
  ) {

    this.translate.onLangChange
      .pipe(takeUntil(this.subscriptionRemover))
      .subscribe((langChangeEvent: LangChangeEvent) => {
        localizeRouterService.changeLanguage(langChangeEvent.lang, {}, false);
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: 'menu.seDeplacer', visibleMobile: true },
      { label: 'menu.enTransportEnCommun' },
      { label: 'menu.transportALaDemande' },
      { label: 'menu.transport-on-demand-allobus', visibleMobile: true},
    ]);
  }

  ngOnDestroy(): void {
    this.subscriptionRemover.next();
    this.subscriptionRemover.complete();
  }

}
