import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';
import { environment } from '../../../../src/environments/environment';
import { IPopupInformation } from '../../shared/popup-information/interfaces/IPopupInformation';

@Injectable({
  providedIn: 'root',
})
export class PopUpInformationService {
  constructor(private httpClient: HttpClient) {}

  popupInformationError = new Subject<string>();

  /**
   * Chargement des données de la home-page
   * - Image de fond
   * - texte navigation
   * - Tuiles
   * Timeout configurable depuis le fichier environnement.ts
   * Gestion d'erreur qui renvoie une erreur à tous les abonnés au service
   * et qui ecrit dans la console le detail de l'erreur
   *
   * @param options pour le webservice (langue, populate, ...  )
   * @returns Un Observable de type IHomePage
   */
  loadAlertInfo(options?: string): Observable<IPopupInformation> {
    return this.httpClient
      .get<any>(
        `${environment.baseUrlApiMgt}/pop-up-information${
          options ? `?${options}` : ''
        }`
      )
      .pipe(
        timeout(environment.timeoutWebServiceCMS),
        map((data: IPopupInformation) => {
          return data;
        }),
        catchError(() => {
          return [];
        })
      );
  }
}
