import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, switchMap, of, takeUntil, filter } from 'rxjs';
import { EditorialPageService } from './editorial-page.service';
import { IEditorialPage } from './interfaces/EditorialPage';
import * as qs from 'qs';
import { DomSanitizer } from '@angular/platform-browser';
import i18nFr from '../../../assets/i18n/fr.json';

@Component({
  selector: 'app-editorial-page',
  templateUrl: './editorial-page.component.html',
  styleUrls: ['./editorial-page.component.scss'],
})
export class EditorialPageComponent implements OnInit, OnDestroy {
  private locale!: string;
  private subscriptionRemover = new Subject<void>();
  public editorialPage$!: Observable<IEditorialPage>;
  private page!: string;
  constructor(
    private translate: TranslateService,
    private router: Router,
    private editorialPageService: EditorialPageService,
    private sanitize: DomSanitizer
  ) {
    this.router.events
      .pipe(
        takeUntil(this.subscriptionRemover),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.locale = this.translate.currentLang;
        if (this.locale === 'fr') {
          const enPage = this.router.url.split('/')[2];
          this.page = this.getObjKey(
            i18nFr.ROUTES,
            decodeURIComponent(enPage)
          ) as string;
          if (!this.page) {
            this.redirectTo404();
          }
        } else {
          this.page = this.router.url.split('/')[2];
        }
        this.editorialPage$ = this.getEditorialPage();
      });
  }

  ngOnInit(): void {}

  private getEditorialPage() {
    const option = qs.stringify({
      locale: this.locale,
      populate: {
        image: '*',
        page: '*',
        dynamics: {
          populate: {
            image: '*',
            video: '*',
            file: '*',
          },
        },
      },
      filters: {
        page: {
          link: {
            $eq: this.page,
          },
        },
      },
    });
    return this.editorialPageService.retrieveEditorialPage(option).pipe(
      switchMap((editorialPage) => {
        if (!editorialPage.data.length) {
          this.redirectTo404();
        }
        return of(editorialPage.data[0]);
      })
    );
  }

  private getObjKey(routes: any, value: string) {
    return Object.keys(routes).find((key) => routes[key] === value);
  }

  private redirectTo404() {
    this.router.navigate(['404']);
  }

  ngOnDestroy(): void {
    this.subscriptionRemover.next();
    this.subscriptionRemover.complete();
  }
}
