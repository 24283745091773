import { Directive, ElementRef, Input, OnChanges, OnInit } from '@angular/core';
import { IImages } from '../interface/images';
@Directive({
  selector: 'img',
})
export class ImgDirective implements OnInit, OnChanges {
  @Input() media?: IImages | null;
  /*@Input() formats?: (keyof IFormat)[] = [
    'thumbnail',
    'small',
    'medium',
    'large',
  ];*/
  @Input() format?: string;
  private _intersectionObserver: IntersectionObserver | undefined;
  constructor(private el: ElementRef<HTMLImageElement>) {}

  ngOnChanges(): void {
    this.applyLazyLoading();
  }

  ngOnInit(): void {
    this.applyLazyLoading();
  }

  private applyLazyLoading(): void {
    const supports = 'loading' in HTMLImageElement.prototype;
    if (supports) {
      this.el.nativeElement.setAttribute('loading', 'lazy');
      this.setSrcFromDataSrc();
    } else {
      this._intersectionObserver = new IntersectionObserver(
        (entries) => {
          this._CheckForIntersection(entries);
        },
        { threshold: 0.0, rootMargin: '10px' }
      );

      this._intersectionObserver.observe(this.el.nativeElement);
    }
  }

  private _CheckForIntersection = (
    entries: Array<IntersectionObserverEntry>
  ): void => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (entry.isIntersecting) {
        this.setSrcFromDataSrc();
        this._intersectionObserver?.unobserve(this.el.nativeElement);
      }
    });
  };

  private setSrcFromDataSrc(): void {
    if (this.media) {
      const image = this.generateImage();
      if (image.srcset) {
        this.el.nativeElement.setAttribute('srcset', image.srcset);
      }
      if (image.src) {
        this.el.nativeElement.setAttribute('src', image.src);
      }
      if (image.alt) {
        this.el.nativeElement.setAttribute('alt', image.alt);
      }
    } else {
      const dataSrc = this.el.nativeElement.getAttribute('data-src');
      if (dataSrc) {
        this.el.nativeElement.setAttribute('src', dataSrc);
        this.el.nativeElement.removeAttribute('data-src');
      }
    }
  }
  private generateImage(): {
    src?: string | undefined;
    srcset?: string | undefined;
    alt?: string | undefined;
  } {
    const img: { src?: string; srcset?: string; alt?: string } = {
      alt: this.media?.attributes?.alternativeText,
    };

    if (this.media?.attributes?.formats && this.format !== undefined) {
      if (this.media?.attributes?.formats?.[this.format]) {
        if (!img.src) {
          img.src = this.media.attributes.formats[this.format].url;
        }
        if (img.srcset) {
          img.srcset = `${this.media.attributes.formats[this.format].url} ${
            this.media.attributes.formats[this.format].width
          }w`;
        } else {
          img.srcset = `, ${this.media.attributes.formats[this.format].url} ${
            this.media.attributes.formats[this.format].width
          }w`;
        }
      }
    }
    if (!img.src) {
      if (this.format !== undefined) {
      }

      img.src = this.media?.attributes.url;
    }

    return img;
  }
}
