import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import {
  IFooter,
  IPages,
} from 'src/app/layout/footer-dynamic/interfaces/footer';
import { environment } from 'src/environments/environment';
import { FooterService } from './footer.service';
import * as qs from 'qs';
import { switchMap, takeUntil, tap } from 'rxjs/operators';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer-dynamic',
  templateUrl: './footer-dynamic.component.html',
  styleUrls: ['./footer-dynamic.component.scss'],
})
export class FooterDynamicComponent implements OnInit, OnDestroy {
  @Input() format?: string;
  private subscriptionRemover = new Subject<void>();
  public footerContent$!: Observable<IFooter>;
  public locale: string;

  constructor(
    private footerservice: FooterService,
    private translate: TranslateService
  ) {
    this.locale = this.translate.currentLang;
    this.translate.onLangChange
      .pipe(takeUntil(this.subscriptionRemover))
      .subscribe((langChangeEvent: LangChangeEvent) => {
        this.locale = langChangeEvent.lang;
        this.footerContent$ = this.getFooterDynamic();
      });
  }

  ngOnInit(): void {
    this.footerContent$ = this.getFooterDynamic();
  }

  scrollToTop(): void {
    window.scrollTo(0, 0);
  }

  ngOnDestroy(): void {
    this.subscriptionRemover.next();
    this.subscriptionRemover.complete();
  }

  private getFooterDynamic() {
    return (this.footerContent$ = this.footerservice
      .getFooterContent(
        qs.stringify({
          locale: this.locale,
          populate: {
            social: {
              populate: {
                social_links: {
                  populate: '*',
                },
              },
            },
            informations: {
              populate: {
                information_links: {
                  populate: {
                    page: '*',
                  },
                },
              },
            },

            application: {
              populate: {
                application_links: {
                  populate: '*',
                },
              },
            },
            contact: {
              populate: {
                contact_links: {
                  populate: ['icon', 'page'],
                },
              },
            },
          },
        })
      )
      .pipe(
        switchMap((footer) => {
          footer.data.attributes.social.social_links.forEach((footer) => {
            if (footer.page) {
              footer.route = footer.page
                ? this.translate.instant(
                    `ROUTES.${footer.page.data.attributes.link}`
                  )
                : footer.link;
            }
          });

          footer.data.attributes.informations.information_links.forEach(
            (footer) => {
              if (footer.page) {
                footer.route = footer.page
                  ? this.translate.instant(
                      `ROUTES.${footer.page.data.attributes.link}`
                    )
                  : footer.link;
              }
            }
          );
          footer.data.attributes.application.application_links.forEach(
            (footer) => {
              if (footer.page) {
                footer.route = footer.page
                  ? this.translate.instant(
                      `ROUTES.${footer.page.data.attributes.link}`
                    )
                  : footer.link;
              }
            }
          );
          footer.data.attributes.contact.contact_links.forEach((footer) => {
            if (footer.page) {
              footer.route = footer.page
                ? this.translate.instant(
                    `ROUTES.${footer.page.data.attributes.link}`
                  )
                : footer.link;
            }
          });
          return of(footer.data);
        })
      ));
  }
}
