import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bandeau-alertes',
  templateUrl: './bandeau-alertes.component.html',
  styleUrls: ['./bandeau-alertes.component.scss'],
})
export class BandeauAlertesComponent implements OnInit {
  visible = false;
  constructor() {}

  ngOnInit(): void {}
}
