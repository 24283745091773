import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-app-not-found',
  templateUrl: './app-not-found.component.html',
})
export class AppNotFoundComponent implements OnInit, OnDestroy {
  public locale!: string;
  private subscriptionRemover = new Subject<void>();
  constructor(private translate: TranslateService, private router: Router) {
    this.locale = this.translate.currentLang;

    this.translate.onLangChange
      .pipe(takeUntil(this.subscriptionRemover))
      .subscribe((langChangeEvent: LangChangeEvent) => {
        this.locale = langChangeEvent.lang;
        this.router.navigate(['/', '404']);
      });
  }

  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.subscriptionRemover.next();
    this.subscriptionRemover.complete();
  }
}
