<div class="editorial-video">
  <video
    *ngIf="item"
    controls
    class="editorial-main-video-uploaded"
  >
    <source [src]="item.video.data.attributes.url" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</div>
