<div class="footer-map-wrapper">
  <a
    class="footer-fix-content"
    routerLink="{{ 'ROUTES.privacy' | translate }}"
    translate
  >
    footer.confidentialite</a
  >
  <a
    class="footer-fix-content"
    routerLink="{{ 'ROUTES.site-map' | translate }}"
    translate
  >
    footer.plan</a
  >
  <a
    class="footer-fix-content"
    translate
    routerLink="{{ 'ROUTES.legal-information' | translate }}"
    >footer.legale</a
  >
  <a
    class="footer-fix-content"
    translate
    routerLink="{{ 'ROUTES.cookies' | translate }}"
    >footer.cookies</a
  >
  <!-- <a
    class="footer-fix-content"
    translate
    routerLink="{{ 'ROUTES.accessibility' | translate }}"
    >footer.accessibilité</a
  > -->
</div>
