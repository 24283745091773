import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer-fix',
  templateUrl: './footer-fix.component.html',
  styleUrls: ['./footer-fix.component.scss'],
})
export class FooterFixComponent implements OnInit {
  public buildNumber = environment.buildNumber;

  constructor() {}

  ngOnInit(): void {}

  scrollToTop(): void {
    window.scrollTo(0, 0);
  }
}
