import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { filter, Subject, Subscription, takeUntil } from 'rxjs';
import { AppComponent } from './app.component';
import { LayoutService } from './layout/layout.service';
import { EventsCtwService } from './services/events-ctw.service';
import { SearchModalComponent } from './shared/search-modal/search-modal.component';

@Component({
  selector: 'app-main',
  templateUrl: './app-main.component.html',
  styleUrls: ['./app-main.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
/**
 * Composant principal abonné au service LayoutService pour actualiser
 * le footer(Mode classique ou carto)
 */
export class AppMainComponent implements OnInit, AfterViewInit, OnDestroy {
  public isMapDisplayed = false;
  public context!: string;
  public disruptionId!: string;
  sideNavOpened = false;
  private abonnements = new Subscription();
  public showOnHomepage = false;
  public locale!: string;
  private subscriptionRemover = new Subject<void>();
  constructor(
    public app: AppComponent,
    private layoutService: LayoutService,
    private cd: ChangeDetectorRef,
    public router: Router,
    public localizeRouter: LocalizeRouterService,
    private translate: TranslateService,
    public dialog: MatDialog,
    public eventCtwService: EventsCtwService,
    public route: ActivatedRoute
  ) {
    this.locale = this.translate.currentLang;
    this.translate.onLangChange
      .pipe(takeUntil(this.subscriptionRemover))
      .subscribe(
        (langChangeEvent: LangChangeEvent) =>
          (this.locale = langChangeEvent.lang)
      );

    this.router.events
      .pipe(
        takeUntil(this.subscriptionRemover),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe(() => {
        this.showOnHomepage = this.router.url === `/${this.locale}`;
      });

    this.layoutService.showSearchModal().subscribe((value: any) => {
      if (value) {
        const dialogRef = this.dialog.open(SearchModalComponent, {});
        dialogRef.afterClosed().subscribe(() => {
          this.layoutService.setSearchModalDisplayed(false);
        });
      } else {
        this.dialog.closeAll();
      }
    });
    this.route.queryParams.subscribe((params) => {
      this.disruptionId = params.disruptionId;
      this.context = params.context;
    });
  }

  ngOnInit(): void {
    this.abonnements.add(
      this.layoutService.LayoutMap().subscribe((value) => {
        this.isMapDisplayed = value;

        this.cd.detectChanges();
      })
    );
  }
  ngAfterViewInit(): void {}
  ngOnDestroy(): void {
    this.abonnements.unsubscribe();
    this.showOnHomepage = false;
    this.subscriptionRemover.next();
    this.subscriptionRemover.complete();
  }
  raisedEvent(event: any): void {
    const queryParamsObj: any = {};
    event.detail.parameters.set('context', 'DisruptionDetail');
    event.detail.parameters.forEach((value: boolean, key: string) => {
      queryParamsObj[key] = value;
    });
    this.router.navigate(
      [
        '/',
        this.locale,
        this.translate.instant('ROUTES.public-transport-info'),
      ],
      {
        queryParams: queryParamsObj,
      }
    );
  }
}
