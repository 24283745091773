import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { LayoutService } from '../layout.service';
@Component({
  selector: 'app-menu-footer',
  templateUrl: './menu-footer.component.html',
  styleUrls: ['./menu-footer.component.scss'],
})
export class MenuFooterComponent implements OnInit, OnDestroy {
  @Output() public sidenavToggle = new EventEmitter();
  @Output() public sidenavClose = new EventEmitter();
  private subscriptionRemover = new Subject<void>();

  public locale!: string;
  selectedMenu!: string;
  private abonnements = new Subscription();
  constructor(
    private layout: LayoutService,
    private translate: TranslateService
  ) {
    this.locale = this.translate.currentLang;
    this.translate.onLangChange
      .pipe(takeUntil(this.subscriptionRemover))
      .subscribe((langChangeEvent: LangChangeEvent) => {
        this.locale = langChangeEvent.lang;
      });
  }

  ngOnInit(): void {
    this.abonnements.add(
      this.layout.SelectedMenu().subscribe((menu) => {
        this.selectedMenu = menu;
      })
    );
  }

  public onToggleSidenav = () => this.sidenavToggle.emit();
  public onCloseSidenav = () => this.sidenavClose.emit();

  navigateTo(page: string) {
    //this.selectedMenu = page;
    // this.layout.setSelectedMenu(page);
    this.onCloseSidenav();
  }
  ngOnDestroy(): void {
    this.abonnements.unsubscribe();
    this.subscriptionRemover.next();
    this.subscriptionRemover.complete();
  }

  showSearchModal(): void {
    this.layout.setSearchModalDisplayed(true);
  }
}
