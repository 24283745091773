import { Component, OnInit, Input } from '@angular/core';
import { IDynamicText } from '../../interface/dynamics';

@Component({
  selector: 'app-dynamic-text',
  templateUrl: './dynamic-text.component.html',
  styleUrls: ['./dynamic-text.component.scss'],
})
export class DynamicTextComponent implements OnInit {
  @Input() item?: IDynamicText;
  constructor() {}

  ngOnInit(): void {}
}
