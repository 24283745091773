import { Component, OnInit, Input } from '@angular/core';
import { IDynamicVideo } from '../../interface/dynamics';

@Component({
  selector: 'app-dynamic-video',
  templateUrl: './dynamic-video.component.html',
  styleUrls: ['./dynamic-video.component.scss'],
})
export class DynamicVideoComponent implements OnInit {
  @Input() item!: IDynamicVideo;
  constructor() {}

  ngOnInit(): void {}
}
