import { Injectable } from '@angular/core';
import { Locale } from '../shared/interface/localizations';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  get engine(): Storage {
    return localStorage;
  }

  constructor() {}

  getData(key: string): string | null | any {
    return this.engine.getItem(key);
  }

  setDataJson(key: string, data: string): void {
    const jsonData = JSON.stringify(data);
    this.engine.setItem(key, jsonData);
  }

  setDataString(key: string, data: string): void {
    this.engine.setItem(key, data);
  }

  setJson(key: string, data: any): void {
    this.engine.setItem(key, JSON.stringify(data));
  }

  removeData(key: string): void {
    this.engine.removeItem(key);
  }

  clear(): void {
    this.engine.clear();
  }
}
