<div fxHide.sm fxHide.xs class="breadcrumb-mrn-color" role="region">
  <ul class="breadcrumb-mrn">
    <li class="breadcrumb-mrn-item">
      <a
      class="breadcrump-home"
        routerLink="/{{ locale }}"
        aria-label="Accueil"
        (click)="navigateToAccueil()"
      >
        <mat-icon>{{ home.icon }}</mat-icon></a
      >
    </li>
    <li class="breadcrumb-mrn-item" *ngFor="let item of items">
      <a
        class="breadcrumb-link"
        *ngIf="item.routerLink !== undefined && item.label !== undefined"
        aria-label="item.label"
        routerLink="{{ item.routerLink | translate }}"
      >
        {{ item.label | translate }}</a
      >
      <span *ngIf="item.routerLink === undefined && item.label !== undefined">
        {{ item.label | translate }}</span
      >
    </li>
  </ul>
</div>
<div class="breadcrumb-mrn-mobile" fxHide.gt-sm>
  <ul class="breadcrumb-mrn">
    <li class="breadcrumb-mrn-mobile-item">
      <a
      class="breadcrump-home"
        routerLink="/{{ locale }}"
        aria-label="Accueil"
        (click)="navigateToAccueil()"
      >
        <mat-icon ariab-label="Acceuil" style="color: white">{{ home.icon }}</mat-icon></a
      >
    </li>
    <li
      class="breadcrumb-mrn-mobile-item"
      *ngFor="let item of items | breadcrumbMobile"
    >
      <a
        class="breadcrumb-mobile-link"
        *ngIf="item.routerLink !== undefined && item.label !== undefined"
        aria-label="item.label"
        routerLink="{{ item.routerLink | translate }}"
      >
        {{ item.label | translate }}</a
      >
      <span *ngIf="item.routerLink === undefined && item.label !== undefined">
        {{ item.label | translate }}</span
      >
    </li>
  </ul>
</div>
