import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as qs from 'qs';
import { StorageService } from 'src/app/services/storage.service';
import { IPopupInformation } from './interfaces/IPopupInformation';
import { PopUpInformationService } from './pop-up-information.service';
import { PopupInformationDialogComponent } from './popup-information-dialog/popup-information-dialog.component';
@Component({
  selector: 'app-popup-information',
  template: ``,
})
/**
 * Composant sans affichage,
 * Appel au service pour demander les informations *
 * La popup est appelée si les conditions metier sont valides
 * les données sont injectée dynamiquement
 *
 * Ce composant est utilisé depuis le App.MainComponent
 */
export class PopupInformationComponent implements OnInit {
  locale: string;
  dataAlert!: IPopupInformation;

  constructor(
    public dialog: MatDialog,
    private popUpInformationService: PopUpInformationService,
    private translate: TranslateService,
    private storage: StorageService
  ) {
    this.locale = this.translate.currentLang;
  }

  ngOnInit(): void {
    const request = qs.stringify({
      locale: this.locale,
      populate: {
        image: '*',
      },
    });

    this.popUpInformationService.loadAlertInfo(request).subscribe((value) => {
      this.processResponseAlert(value);
    });
  }
  /**
   * Valide les conditions d'affichage du popup
   * critere 1 : date de publication
   *
   */
  processResponseAlert(information: IPopupInformation): void {
    const dialogStored = this.storage.getData('pop-up-dialog');
    const dialogParse = JSON.parse(dialogStored);

    if (dialogStored) {
      const dialogDate = new Date(dialogParse.expireAt);
      const date = new Date();
      if (date < dialogDate) {
        return;
      }
    }

    let result = false;
    let currentDate = new Date();
    let display_start = new Date(information.data.attributes.display_start);
    let display_end = new Date(information.data.attributes.display_end);

    // Check date condition
    result = display_start <= currentDate && currentDate <= display_end;
    if (result) {
      this.dataAlert = information;
      this.openDialogAlert();
    }
  }

  /**
   * Methode permettant d'afficher le popup information
   */
  openDialogAlert() {
    const dialog = this.dialog.open(PopupInformationDialogComponent, {
      data: this.dataAlert,
      maxWidth: '80%',
    });

    dialog.afterClosed().subscribe((result) => {
      if (result) {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        this.storage.setJson('pop-up-dialog', { expireAt: date.toString() });
      }
    });
  }
}
