<mat-accordion multi>
    <mat-expansion-panel class="dynamic-accordion">
      <mat-expansion-panel-header>
        <mat-panel-title class="dynamic-accordion-title">
          {{ item?.title }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <p class="dynamic-accordion-content" [innerHTML]="item?.content| safeHtml"></p>
    </mat-expansion-panel>
</mat-accordion>
  